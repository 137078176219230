<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-3/5 space-y-7">
      <div class="card">
        <div class="p-4">
          <h1 class="text-2xl font-semibold">{{ sheet.title }}</h1>
          <p v-if="sheet.composers && sheet.composers.data && sheet.composers.data.length">
            Sáng tác:
            <span class="people-in-song">
              <people-of-song :people="sheet.composers.data" type="composer"
                              class="font-semibold"/>
            </span>
          </p>
          <p>
            Năm sáng tác: {{ sheet.year }}
          </p>
          <p>Lượt xem: {{ sheet.views }}</p>
          <p v-if="sheet.uploader">
            Đóng góp bản nhạc: 
            <router-link :to="{name: 'UserDetail', params: {id: sheet.uploader.id}}" v-if="sheet.uploader"
                           :title="sheet.uploader.username">
                {{ sheet.uploader.username }}
              </router-link>
          </p>
          <tag :tags="sheet.tags" v-if="sheet.tags && sheet.tags.length"/>
          <div class="flex items-center space-x-5 my-3 pb-4 border-b">
            <div v-if="canManageSheet">
              <router-link :to="{name: 'AdminSheetEdit', params: {id: sheet.id}}"
                           class="flex items-center justify-center h-9 lg:px-5 px-2 rounded-md bg-green-600 text-white space-x-1.5">
                <i class="uil-pen mr-1"></i>Sửa
              </router-link>
            </div>
          </div>
        </div>
        <div class="content" v-html="sheet.content"></div>
      </div>

      <comment object-type="sheet" :comment-id="commentId" :object-id="id" field="id" type="ID"/>
      <div class="card mt-4 p-3" v-if="songs.data && songs.data.length">
        <h3 class="text-xl font-semibold mb-2 md:flex items-center justify-between">
          Các bản thu liên quan ({{ songs.paginatorInfo.total }})
        </h3>
        <song-item v-for="song in songs.data" :song="song" prefix="bai-hat" :key="'song' + song.id"/>
      </div>
    </div>
    <div class="lg:w-2/5 w-full">
      <div class="card p-3">
        <latest-comment/>
      </div>
      <right/>
    </div>
  </div>
</template>

<script>
import Right from "../right/Right";
import ApiService from "../../core/services/api.service";
import {canManageSheet, changePageTitle, logActivity, timeago} from "../../core/services/utils.service";
import SongItem from "../SongItem";
import Tag from "../Tag";
import Comment from "../comment/Comment";
import LatestComment from "../comment/LatestComment";
import PeopleOfSong from "../song/PeopleOfSong";

export default {
  name: "SheetDetail",
  components: {
    LatestComment, PeopleOfSong,
    Right, SongItem, Comment, Tag
  },
  data() {
    return {
      id: null,
      commentId: null,
      sheet: {
        id: 0,
        title: "",
        slug: "",
        year: "",
        content: ""
      },
      songs: {
        data: [],
        paginatorInfo: {
          total: 0
        }
      }
    }
  },
  methods: {
    loadSheet() {
      let query = `query($id: ID!) {
        sheet(id: $id) {
          id
          title
          slug
          views
          year
          content
          created_at
          uploader {
            id
            username
            avatar {
              url
            }
          }
          composers(first: 20) {
            data {
              id
              title
              slug
            }
          }
          tags {
            id
            name
            slug
          }
          songs(first: 100) {
            data {
              id
              title
              slug
              views
              thumbnail {
                url
              }
              artists(first: 20) {
                data {
                  id
                  title
                  slug
                }
              }
            }
            paginatorInfo {
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.sheet) {
              this.sheet = data.data.sheet;
              changePageTitle(this.sheet.title);
              if (this.sheet.songs) {
                this.songs = this.sheet.songs;
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    timeago(x) {
      return timeago(x);
    }
  },
  computed: {
    canManageSheet() {
      return canManageSheet();
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.commentId = this.$route.query.comment_id;
    this.loadSheet();
    logActivity("view", "sheet", this.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.commentId = this.$route.query.comment_id;
    window.scrollTo(0, 0);
    next();
    this.loadSheet();
  },
}
</script>
